.counter {
  margin: 5rem auto;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
  width: 40rem;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  background-color: #f4f0fa;
}

.counter h1 {
  text-transform: uppercase;
  color: #575757;
  margin: 0;
  font-size: 1rem;
}

.value {
  font-size: 2rem;
  color: #3c0080;
  margin: 2rem 0;
  font-weight: bold;
}

.counter button{
  margin: 1rem;
}