.header {
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #3c0080;
  color: white;
  padding: 0 10%;
}

.header ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
}

.header li {
  margin: 0 1rem;
}

.header a {
  text-decoration: none;
  color: white;
  font-size: 1.25rem;
}

.header a:hover,
.header a:active {
  color: #b864da;
}

.header button {
  font-size: 1.25rem;
  background-color: #ffbb00;
  border: 1px solid #ffbb00;
  padding: 0.5rem 1.5rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  color: #2c2922;
}

.header button:hover,
.header button:active {
  background-color: #ffa600;
  border-color: #ffa600;
}
